<template>
  <div class="projects mb-4">
    <header class="projects-header">
      <TitleSection
        :title="'Asignación de Articulos'"
        :subtitle="
          'Gestiona los articulos de las boleterias que podrán vender en el sistema'
        "
      />
    </header>
    <div class="row py-4">
      <div class="col-lg-3 col-md-3 col-sm-12 pb-2 pt-0">
        <v-autocomplete
          label="Boletería"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          single-line
          :items="boxOffices"
          item-text="DESCRIPCION"
          :multiple="false"
          return-object
          small-chips
          @change="getCategoriesByBoxOffices"
          v-model="assignment.boxOffice"
        >
        </v-autocomplete>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 pb-2 pt-0">
        <v-autocomplete
          label="Categoría"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled || loading"
          :loading="loading"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          single-line
          :items="boxCategories"
          item-text="DESCRIPCION"
          :multiple="false"
          return-object
          small-chips
          @change="getAssignmentByCategoriesBoxOffices"
          v-model="assignment.boxCategorie"
        >
        </v-autocomplete>
      </div>
    </div>

    <TableAssignmentsArticles
      :assignments="assignments"
      :loadingAction="loadingAction"
      @openRegister="registerAssignment"
    >
    </TableAssignmentsArticles>
  </div>
</template>

<script>
import TitleSection from "@/components/titles/TitleSection";
import TableAssignmentsArticles from "@/components/tables/TableAssignmentsArticles";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import axios from "@/api/entradaslima";
import store from "../../store";
export default {
  components: {
    TitleSection,
    TableAssignmentsArticles
  },
  data() {
    return {
      rounded: false,
      color: "#738297",
      bgColor: "#ffffff",
      required: [v => !!v || "Requerido"],
      upToZero: [v => (!!v && v > 0) || "Incorrecto"],
      disabled: false,
      details: true,
      filled: true,
      outlined: false,
      dense: true,
      autocomplete: "off",
      readonly: true,
      dark: false,
      dialog: false,
      loadingAction: -1,
      loading: false,
      assignment: {
        boxOffice: {},
        boxCategorie: {}
      },
      idParque: store.getters.getSessionParqueId,
      usuario: store.getters.getSessionUser.CORREO,
      assignments: [],
      boxOffices: [],
      boxCategories: [],
      users: []
    };
  },
  computed: {
    getTitle() {
      return "Nueva Asignación";
    },
    getSubtitle() {
      return "Rellena todos los campos para crear una nueva asignación";
    }
  },
  async mounted() {
    await this.getBoxOffices();
  },
  methods: {
    ...mapActions({
      generateConfirmDialog: "generateConfirmDialog",
      generateSnackBarMessage: "generateSnackBarMessage"
    }),
    async getBoxOffices() {
      try {
        const { data, status } = await axios.get(
          `/parques/${this.idParque}/boleterias`
        );
        if (status != 200) return;
        this.boxOffices = data.data;
      } catch (error) {
        return;
      }
    },
    async getCategoriesByBoxOffices(boxOffice) {
      try {
        this.loading = true;
        this.assignment.boxCategorie = {};
        this.assignments = [];
        const { data, status } = await axios.get(
          `/parques/${this.idParque}/boleterias/${boxOffice.IDBOLETERIA}/categorias`
        );
        this.loading = false;
        if (status != 200) return;
        this.boxCategories = data.data.filter(item => item.ESTADO === 1);
      } catch (error) {
        this.loading = false;
        return;
      }
    },
    async getAssignmentByCategoriesBoxOffices(categories) {
      try {
        const { data } = await axios.get(
          `/parques/${this.idParque}/boleterias/${this.assignment.boxOffice
            .IDBOLETERIA || 0}/categorias/${categories.IDCATEGORIA}/articulos`
        );
        this.assignments = data.data;
      } catch (error) {
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    },
    async registerAssignment(dto) {
      const response = await this.generateConfirmDialog({
        message: `¿Esta seguro de ${
          dto.ESTADO === 1 ? "activar" : "desactivar"
        } la asignación?`,
        confirmMessage: "Si, quiero continuar"
      });
      if (response) {
        try {
          this.loadingAction = dto.IDARTICULO;
          const { data } = await axios.post(
            `/parques/${this.idParque}/boleterias/${this.assignment.boxOffice
              .IDBOLETERIA || 0}/categorias/${dto.IDCATEGORIA}/articulos/${
              dto.IDARTICULO
            }`,
            {
              estado: dto.ESTADO,
              usuario: this.usuario
            }
          );
          this.loadingAction = -1;
          this.generateSnackBarMessage({
            message: data.message
          });
          await this.getAssignmentByCategoriesBoxOffices(
            this.assignment.boxCategorie
          );
        } catch (error) {
          this.loadingAction = -1;
          return Swal.fire({
            icon: "error",
            text: error.response.data.message || "Ocurrió un error interno"
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
