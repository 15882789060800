<template>
  <v-data-table
    :headers="headers"
    :items="assignments"
    :items-per-page="10"
    class="elevation-0 projects-table"
  >
    <template v-slot:[`item.DESCRIPCION_ESTADO`]="{ item }">
      <v-chip
        class="mb-0"
        small
        :color="item.ESTADO == '1' ? 'green' : 'red'"
        >{{ item.DESCRIPCION_ESTADO }}</v-chip
      >
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex justify-content-end">
        <template v-if="item.IDASIGNACIONARTICULO > 0">
          <v-btn
            class="action-btn"
            title="Desactivar"
            :loading="item.IDARTICULO === loadingAction"
            @click="$emit('openRegister', { ...item, ESTADO: 0 })"
            small
            icon
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
              />
            </svg>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            class="action-btn"
            title="Activar"
            :loading="item.IDARTICULO === loadingAction"
            @click="$emit('openRegister', { ...item, ESTADO: 1 })"
            small
            icon
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
              />
            </svg>
          </v-btn>
        </template>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment-timezone";
export default {
  components: {},
  props: {
    assignments: Array,
    loadingAction: Number
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "IDARTICULO"
        },
        {
          text: "Articulo",
          sortable: false,
          value: "DESCRIPCIONCORTA" // cambiar value
        },
        {
          text: "Estado",
          sortable: false,
          value: "DESCRIPCION_ESTADO" // cambiar value
        },
        {
          text: "",
          sortable: false,
          value: "actions"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
